import React from "react";
import { Button } from "../UI";
import { whyConfig } from "./config";

import cn from "classnames";
import styles from "./WhySection.module.scss";

const WhySection = () => {
  return (
    <section className={styles["why"]} id="why-us">
      <div className={"wrapper"}>
        <div className={styles["why__wrapper"]}>
          <div className={styles["why__items"]}>
            <div className={styles["why__description"]}>
              <h2 className={cn(styles["why__title"], "main-title")}>
                Why Choose Secure My Phone
              </h2>
              <p className={cn(styles["why__subtitle"], "main-subtitle")}>
                Detect spyware on your phone, see who tried to unlock it,
                protect your data.Usually, spyware disguises itself as harmless
                software like a parental control or account sharing program. You
                can reveal hidden spyware with the Clario Anti Spy app.
              </p>
              <ul className={styles["why__list"]}>
                {whyConfig.map(({ icon, title }, i) => {
                  return (
                    <li className={styles["why__list-item"]} key={`why-${i}`}>
                      {icon ? icon : ""}
                      {title}
                    </li>
                  );
                })}
              </ul>
              <Button
                className={styles["button"]}
                to="/device"
                theme={"white"}
                rel="nofollow"
              >
                Get started
              </Button>
            </div>

            <div className={styles["why__image"]}>
              <img
                className={styles["image"]}
                srcSet={"/images/home/why.png 1x, /images/home/why@2x.png 2x"}
                src={"/images/home/why.png"}
                alt="why"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
